/* src/components/Navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.navbar {
  background: #ffffff; /* White background */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #000000; /* Black border */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1500px;
  padding: 0 20px;
}

.navbar-logo {
  color: #000000; /* Black color for the logo */
 
  cursor: pointer;
  text-decoration: none;
  
  display: flex;
  width: 10px;
  align-items: center;
  transition: all 0.3s ease;
}
.logo{
  width: 150px;
}

.navbar-logo:hover {
  color: #ff6600; /* Orange color on hover */
}

.menu-icon {
  display: none;
  color: #000000; /* Black color for the menu icon */
  font-size: 2rem;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #000000; /* Black color for the links */
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
}

.nav-links:hover {
  color: #ff6600; /* Orange color on hover */
/* Black border on hover */
  transform: scale(1.1);
}



/* Navbar.css */

/* User item in the navbar */
.user-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto; /* Push the user info to the far right */
}

.user-info {
  display: flex;
  align-items: center;

  padding: 10px;
  cursor: pointer;
}

.user-info img {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.username {
  
  font-size: 1rem;
  font-weight: bold;
}

/* Dropdown menu */
.user-dropdown {
  position: absolute;
  top: 60px; /* Adjust based on your navbar height */
  right: 0;
  width: 150px;
  background-color: #ff5500;
 
  border-radius: 5px;

  z-index: 10;

}

.dropdown-link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px;
 
}

.dropdown-link:last-child {
  border-bottom: none;
}
.aa{
  margin-top: 10px;
  font-size: 28px;
  color: #ff5500;
}


button {
width: 100%;
  
  color: #fff;
  background: none;
  font-size: 17px;
  border: none;
  font-weight: bold;
  padding: 10px;

  cursor: pointer;
}

button:hover {
  color: #ff5500;
  border: 1px solid #ff5500;
  background-color: #ffffff;
}









@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -150%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #ffffff; /* White background */
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #ff6600; /* Orange color on hover */
    transform: scale(1.1);
    /* Black border on hover */
  }

  .user-item {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .user-info img {
    margin-top: 0;
    margin-left: 80%;
  }

  .user-dropdown {
    position: static;
    width: 100%;
    top: 0;
    margin-right: 100px;
    border-radius: 0;
  }

  .dropdown-link, button {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #444;
  }
}

/* comments.css */

.comment {
  display: block;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin: 15px 0;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.comment:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.user-info {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.username {
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 10px;
  color: #333;
}

.review {
  display: flex;
}

.stars {
  color: #b55100;
  margin-right: 3px;
}

.comment-text {
  font-size: 1em;
  color: #555;
  align-self: center;
  margin-bottom: 15px;
  line-height: 1.4;
}
.imagediv{
  margin-top: 20px;
}
.comment img {
  max-width: 500px;
  max-height: 500px;
 
  object-fit: cover;
  border: 2px solid #e0e0e0;
  margin-top: 10px;
  align-self: center;
}
.imagediv img {
  cursor: pointer;
  width: 100px; /* Adjust size as needed */
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.modal-image:hover {
  transform: scale(1.1);
  transition: transform 0.3s;
}
@media (min-width: 768px) {
  .comment {
    flex-direction: row;
    align-items: flex-start;
  }

  .user-info {
    margin-bottom: 0;
    margin-right: 20px;
    flex-shrink: 0;
  }

  .comment-text {
    flex: 1;
    margin-bottom: 0;
  }

  .comment img {
    margin-top: 0;
    margin-left: 20px;
  }
}

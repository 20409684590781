.authlayout {
  height: 100vh;
  overflow-x: hidden;
  background: #D3D3D3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.authlayout_logo {
  border-radius: 90%;
  padding: 2em;
  margin-bottom: 4em;
  animation: float 5s ease-in-out infinite;
}
.authlayout_logo img {
  max-width: 150px;
}
.authlayout_actions {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  margin-top: 2em;
  padding: 0 2px;
}
.authlayout_actions p {
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 300;
}
.authlayout_actions-l {
  color: #ff6200;
}
.authlayout_actions-r {
  color: #000000;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0 #ff863b;
    transform: translateX(0);
  }
  25% {
    box-shadow: 0 25px 15px 0 #ffb362;
    transform: translateX(5px);
  }
  50% {
    box-shadow: 0 25px 15px 0 #ffbb72;
    transform: translateY(-5px);
  }
  75% {
    box-shadow: 0 25px 15px 0 #fe8300;
    transform: translateX(5px);
  }
  100% {
    box-shadow: 0 5px 15px 0 #ffb86c;
    transform: translateX(0);
  }
}
@media (min-width: 1200px) {
  .authlayout_actions {
    max-width: 370px;
  }
}/*# sourceMappingURL=AuthLayout.css.map */
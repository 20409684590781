/* AffiliatePage.css */

.affiliate-page {
    font-family: 'Arial', sans-serif;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
margin-top: 150px;
    min-height: 100vh;
    padding: 0 20px;
  }
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .affiliate-page__header {
    background: #ff6600;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    
    color: white;
    margin-bottom: 30px;
  }
  
  .affiliate-page__heading {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .affiliate-page__main {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
  }
  
  .affiliate-page__form {
    display: flex;
    flex-direction: column;
  }
  
  .affiliate-page__label {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .affiliate-page__input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    width: calc(100% - 22px);
  }
  
  .affiliate-page__input:focus {
    border-color: #ff6600;
    outline: none;
  }
  
  .affiliate-page__button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #ff6600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .affiliate-page__button:hover {
    background-color: #ff6600;
  }
  
  @media (max-width: 600px) {
    .affiliate-page__main {
      padding: 20px;
    }
  
    .affiliate-page__heading {
      font-size: 2rem;
    }
  
    .affiliate-page__input, .affiliate-page__button {
      font-size: 0.9rem;
    }
  }
  
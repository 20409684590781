/* ProductPage.css */
.product-page {
  padding: 20px;
}

.product-page-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  align-items: center;
}

.product-image-container {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.product-details {
  width: 30%;
  padding-left: 50px;
  align-self: flex-start;
}

.product-name {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.product-price {
  font-size: 1.5rem;
  color: #ff6600;
  margin-bottom: 20px;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  font-size: 1rem;
  margin-left: 10px;
}

.product-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.product-size,
.product-quantity {
  margin-bottom: 20px;
}

.product-size label,
.product-quantity label {
  font-size: 1.2rem;
  color: #333;
  margin-right: 10px;
}

.product-size select,
.product-quantity input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.buy-now-button {
  background-color: #ff6600;
  color: #fff;
  padding: 12px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-now-button:hover {
  background-color: #fff;
  color: #cc5200;
}

.product-reviews {
  width: 100%;
  max-width: 800px;
}

.product-reviews h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.review {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.star-icon {
  color: #ffcc00;
  margin-right: 5px;
}

.review-author {
  font-size: 1.2rem;
  color: #777;
  margin-left: 10px;
}

.review-text {
  font-size: 1.2rem;
  color: #555;
}

@media (max-width: 600px) {
  .product-page {
    padding: 10px;
  }

  .product-details {
    width: 100%;
  }

  .product-reviews {
    width: 100%;
  }
}

@media (max-width: 1176px) {
  .product-page-container {
    flex-direction: column;
  }
}

/* Add styles for required field error messages */
.required-error {
  color: #ff0000; /* Red color for error messages */
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Add styles for the form */
.payment-form label {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.payment-form input[type="text"],
.payment-form input[type="tel"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.payment-form input[type="text"]:focus,
.payment-form input[type="tel"]:focus {
  border-color: #ff6600;
  box-shadow: 0 0 10px rgba(255, 102, 0, 0.5);
}

.payment-form button[type="submit"] {
  background-color: #ff6600;
  color: #fff;
  padding: 12px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-form button[type="submit"]:hover {
  background-color: #fff;
  color: #cc5200;
}

/* Add styles for the coupon code section */
.coupon-code {
  margin-bottom: 20px;
  display: flex;

}

.coupon-code label {
  font-size: 1.2rem;
  color: #333;
  margin-right: 10px;
}

.coupon-code input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.coupon-code button {
  background-color: #ff6600;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  width: 150px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.coupon-code button:hover {
  background-color: #fff;
  color: #cc5200;
}

/* src/components/PaymentForm.css */

.payment-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-self:flex-start;
    margin-left: 50px;
    margin-top: 20px;
  }
  
  .payment-form h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .payment-form label {
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #555;
  }
  
  .payment-form input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .payment-form button {
    padding: 10px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .payment-form button:hover {
    background-color: #cc5200;
  }
  @media (max-width: 1176px) {
    .payment-form{
      margin: auto;
    }
  
    }
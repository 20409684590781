/* src/components/Banner.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.banner {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-bottom: 2px solid #000000; /* Black border at the bottom */
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Slightly transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content {
  z-index: 2;
  max-width: 800px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1); /* Transparent white background */
  border-radius: 10px;
  animation: fadeIn 1.5s ease-in-out;
}

.banner-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: slideInFromLeft 1s ease-in-out;
}

.banner-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  animation: slideInFromRight 1s ease-in-out;
}

.banner-button {
  background: linear-gradient(45deg, #ff6600, #cc5200); /* Gradient background for the button */
  color: #ffffff; /* White text color */
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s ease, background 0.3s ease;
  font-family: 'Poppins', sans-serif;
  animation: fadeInUp 1.5s ease-in-out;
}

.banner-button:hover {
  transform: scale(1.1);
  color: #cc5200;
  border: #cc5200 1px solid;
  background: linear-gradient(45deg, #ffffff, #ffffff); /* Darker gradient on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .banner {
    height: 70vh;
  }

  .banner-title {
    font-size: 2rem;
  }

  .banner-subtitle {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .banner-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 60vh;
  }

  .banner-title {
    font-size: 1.5rem;
  }

  .banner-subtitle {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .banner-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

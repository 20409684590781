.activate {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  letter-spacing: 2px;
  background: #ff7700;
}
.activate p {
  font-size: 1.5rem;
  color: #00ff08;
  text-transform: capitalize;
  font-weight: bold;
}
.activate p span {
  font-weight: bold;
  cursor: pointer;
  color: #000000;
}/*# sourceMappingURL=activatelayout.css.map */
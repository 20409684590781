.input {
  margin: auto;
  margin-bottom: 30px;
}
.input label {
  position: relative;
  font-size: 1rem;
}
.input label input {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  background-color: #ff8400;
  padding: 1em;
  border-radius: 5px;
  width: 300px;
  outline: none;
  font-size: 1rem;
  border: 2px solid transparent;
}
.input label span {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: 300;
  font-size: 0.85rem;
}
.input label input:not(:-moz-placeholder-shown) + span {
  color: transparent;
  transform: translate3d(0, -6px, 0) scale(0.75);
}
.input label input:not(:placeholder-shown) + span {
  color: transparent;
  transform: translate3d(0, -6px, 0) scale(0.75);
}
.input label input:focus {
  border: 2px solid #000000;
}
.input label input:focus + span {
  top: -15px;
  font-size: 15px;
  background: linear-gradient(to right, #ff6200, #ff6200);
  border-radius: 4px;
  padding: 0 5px;
  color: #fff;
}
.input label .input_icon {
  color: #1d1927;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(92%, -50%);
  width: 100%;
  cursor: pointer;
}

.toast-failed {
  background: #f96a8b !important;
  color: #fff;
}

.toast-success {
  background: #ff8400 !important;
  color: #fff;
}

@media (min-width: 1200px) {
  .input label input {
    width: 370px;
  }}
  @media (max-width: 1200px) {
    .input label input {
      width: 250px;
    }
}/*# sourceMappingURL=input.css.map */
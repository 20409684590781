@import "../variables/variables.scss";

.input {
  margin: 1.5em 0;
  label {
    position: relative;
    font-size: 1rem;
    input {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
      background-color: $purple-light;
      padding: 1em;
      border-radius: 5px;
      width: 300px;
      outline: none;
      font-size: 1rem;
      border: 2px solid transparent;
    }
    span {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(0, -50%);
      color: $purple-lighter;
      transition: all 0.3s ease;
      font-weight: 300;
      font-size: 0.85rem;
    }
    input:not(:placeholder-shown) + span {
      color: transparent;
      transform: translate3d(0, -6px, 0) scale(0.75);
    }
    input:focus {
      border: 2px solid $pink;
    }
    input:focus + span {
      top: -15px;
      font-size: 10px;
      background: linear-gradient(to right, $yellow, $pink);
      border-radius: 4px;
      padding: 0 5px;
      color: #fff;
    }
    .input_icon {
      color: $purple-dark;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(92%, -50%);
      width: 100%;
      cursor: pointer;
    }
  }
}
.toast-failed {
  background: $pink !important;
  color: #fff;
}
.toast-success {
  background: $green !important;
  color: #fff;
}

@include for-desktop {
  .input {
    label {
      input {
        width: 370px;
      }
    }
  }
}